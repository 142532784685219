<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle v-if="subTitle">{{ subTitle }}</v-card-subtitle>
    <loader v-if="loading" />
    <v-card-text
      v-else-if="series.length === 0 || series[0].data.length === 0"
      >{{ noDataText }}</v-card-text
    >
    <div v-else>
      <vue-apex-charts
        type="line"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { noDataText } from "@/utils/constants";

import Loader from "@/components/common/Loader";

export default {
  name: "LineChart",
  components: {
    Loader,
    VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Boolean,
      default: true,
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["#F44336"],
    },
    stroke: {
      type: Object,
      default: () => ({
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [0, 2],
      }),
    },
    height: {
      type: Number,
      default: 350,
    },
    labelsEnabled: {
      type: Boolean,
      default: false,
    },
    yAxis: {
      type: Array,
      default: () => [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
    },
    xAxis: {
      type: Object,
      default: () => ({
        categories: [],
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      }),
    },
  },
  data() {
    return {
      noDataText,
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.chartOptions = {
        ...this.chartOptions,
        labels: this.labels,
        chart: {
          ...this.chartOptions.chart,
          id: this.title,
          height: this.height,
        },
        legend: {
          show: this.legend,
        },
        colors: this.colors,
        dataLabels: {
          enabled: this.labelsEnabled,
        },
        stroke: this.stroke,
        yaxis: this.yAxis,
        xaxis: this.xAxis,
      };
    },
  },
  watch: {
    height() {
      this.initData();
    },
    colors() {
      this.initData();
    },
    labelsEnabled() {
      this.initData();
    },
    stroke() {
      this.initData();
    },
    yAxis() {
      this.initData();
    },
    xAxis() {
      this.initData();
    },
    labels() {
      this.initData();
    },
  },
};
</script>
