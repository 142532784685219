<template>
  <div>
    <v-container class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-container>
    <v-container class="text-center">
      {{ text }}
    </v-container>
  </div>
</template>

<script>
import { dataLoadingText } from "@/utils/constants";

const emptyValue = {
  value: 0,
  raw: 0,
};

export default {
  name: "Loader",
  data() {
    return {
      text: dataLoadingText,
    };
  },
};
</script>

<style lang="scss" scoped></style>
