<template>
  <div>
    <v-expansion-panels class="mb-2" id="expandableFilters">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partner-group-filter store="premium" />
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partner-view-filter store="premium" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <v-row class="d-flex">
            <v-col cols="2">
              <date-filter
                store="premium"
                storeGetter="getMonthDate"
                storeUpdater="updateMonthDate"
                format="month"
                :isRange="false"
              />
            </v-col>
            <v-col cols="4">
              <site-group-filter store="premium" />
            </v-col>
            <v-col cols="4">
              <site-filter store="premium" />
            </v-col>

            <v-col cols="2">
              <v-btn
                @click="resetFilters"
                class="pt-7 pb-6 mt-0 my-1"
                outlined
                color="secondary"
              >
                Réinitialiser
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  created() {
    this.resetFilters();
  },
  data() {
    return {};
  },
  methods: {
    resetFilters() {
      let monthDate = new Intl.DateTimeFormat("fr-FR")
        .format(new Date())
        .split("/");
      this.$store.dispatch(
        "premium/updateMonthDate",
        `${monthDate[2]}-${monthDate[1]}`
      );
      this.$store.dispatch("premium/updatePartnerViewGroups", [9]);
    },
  },
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
