// returns Promise
export const runParallelAsyncs = (promisesMap) => {
  const keys = Object.keys(promisesMap);
  const promises = keys.map((key) => {
    return promisesMap[key];
  });

  return Promise.all(promises).then((results) => {
    return results.reduce((resolved, result, index) => {
      Object.assign(resolved, { [keys[index]]: result });

      return resolved;
    }, {});
  });
};

export const mapAsync = (array, callbackfn) => {
  return Promise.all(array.map(callbackfn));
};

export const mapAsyncAllSettled = async (
  array,
  callbackfn,
  logErrors = false
) => {
  const results = await Promise.allSettled(array.map(callbackfn));

  if (
    logErrors === true &&
    results.some((result) => result.status === "rejected")
  ) {
    console.error(
      `Errors in mapAsyncAllSettled: ${results
        .filter((result) => result.status === "rejected")
        .map((result) => result.reason)
        .toString()}`
    );
  }

  return results;
};
