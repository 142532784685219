<template>
  <line-chart
    title="CA & Sessions vs Objectifs"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
  ></line-chart>
</template>

<script>
import axios, { cacheTime } from "@axios";
import { runParallelAsyncs } from "@/utils/async";
import { colors } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

import LineChart from "@/components/common/charts/LineChart";

export default {
  name: "CaSessionsObjectivesChart",
  components: {
    LineChart,
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      series: [
        {
          name: "CA",
          data: [],
        },
        {
          name: "Objectif Revenue",
          data: [],
        },
        {
          name: "Sessions",
          data: [],
        },
        {
          name: "Objectif Sessions",
          data: [],
        },
      ],
      colors: [colors.ca, colors.ca, colors.sessions, colors.sessions], // "#1095f5", "#1095f5", "#ec0c32", "#ec0c32",
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [0, 9, 0, 9],
      },
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Revenue",
          min: 0,
          show: false,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          title: {
            text: "Sessions",
          },
          seriesName: "Sessions",
          opposite: true,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Sessions",
          show: false,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        let queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };

        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        for (const x of categories) {
          mainData[x] = {
            ca: null,
            sessions: null,
            objRevenue: null,
            objSessions: null,
          };
        }
        const {
          detailedRevenueResponse: { data: detailedRevenueData },
          detailedAudienceResponse: { data: detailedAudienceData },
          totalResponse: { data: totalData },
        } = await runParallelAsyncs({
          detailedRevenueResponse: axios.get(
            "/programmatic/detailed-revenue-monthly-report",
            {
              params: queryParams,
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          detailedAudienceResponse: axios.get(
            "/programmatic/detailed-audience-monthly-report",
            {
              params: queryParams,
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          totalResponse: axios.get("/programmatic/total-monthly-report", {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }),
        });

        delete queryParams["partnersconfigs"];
        queryParams["objtype"] = "sessions";
        const { data: objectivesSessionsData } = await axios.get(
          "/objectives/",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        // align the revenue and the objective revenue on the same scale
        // using the same max value
        const getRevenueMaxValue =
          detailedRevenueData.reduce(
            (acc, curr) => (acc > curr["ca"] ? acc : curr["ca"]),
            0
          ) * 1.1;
        this.yAxis[0].max = getRevenueMaxValue;
        this.yAxis[1].max = getRevenueMaxValue;

        // align the sessions and the objective sessions on the same scale
        // using the same max values;
        const getSessionsMaxValue =
          detailedAudienceData.reduce(
            (acc, curr) => (acc > curr["sessions"] ? acc : curr["sessions"]),
            0
          ) * 1.1;
        this.yAxis[2].max = getSessionsMaxValue;
        this.yAxis[3].max = getSessionsMaxValue;

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
        });
        const objectiveRevenueDay =
          totalData["current_ca_objective"] / categories.length;

        const objectiveSessionsMonth = objectivesSessionsData["value"];
        const objectiveSessionsDay = objectiveSessionsMonth / categories.length;

        // const objectiveRPMDay =
        //  (objectiveRevenueDay / objectiveSessionsDay) * 1000;
        for (const x of categories) {
          mainData[x]["objRevenue"] = objectiveRevenueDay;
          mainData[x]["objSessions"] = objectiveSessionsDay;
        }

        this.series = [
          {
            name: "CA",
            data: categories.map((category) => mainData[category]["ca"]),
          },
          {
            name: "Objectif CA",
            data: categories.map(
              (category) => mainData[category]["objRevenue"]
            ),
          },
          {
            name: "Sessions",
            data: categories.map((category) => mainData[category]["sessions"]),
          },
          {
            name: "Objectif Sessions",
            data: categories.map(
              (category) => mainData[category]["objSessions"]
            ),
          },
        ];
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
