<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les groupes de vues partenaires"
    multiple
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: "PartnerViewGroupFilter",
  props: {
    initialValue: {
      type: Array,
      validator(values) {
        return (
          values.length === 0 ||
          values.every((value) => typeof value === "number")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["premium"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getPartnerViewGroups",
    },
    storeUpdater: {
      type: String,
      default: "updatePartnerViewGroups",
    },
  },
  data() {
    return {
      initialized: false,
      initialUrlValue: null, // in case we need to load from url params
      items: [],
    };
  },
  async created() {
    this.items = await this.$store.dispatch("common/getPartnerViewGroupList");

    console.log("Created", this.$options.name);

    this.initialUrlValue = await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: this.useStore ? `${this.store}/${this.storeUpdater}` : "",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.selected,
      is_multiple: true,
      is_integer: true,
      // is_boolean: false,
      // dependsOn: undefined,
    });

    this.initialized = true;
  },
  computed: {
    useStore() {
      return this.store !== undefined;
    },
    selected: {
      get() {
        if (this.useStore) {
          return this.$store.getters[`${this.store}/${this.storeGetter}`];
        }

        return this.initialUrlValue ?? this.initialValue;
      },
      set(e) {
        if (this.useStore === false) {
          if (e !== (this.initialUrlValue ?? this.initialValue)) {
            this.$emit("valueChanged", e);
          }

          return;
        }

        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
  watch: {
    selected(newValue) {
      if (this.initialized) {
        // Only update user changes, not initialization
        addQueryStringParam(
          this.$router,
          this.$route,
          URL_PARAM_NAMES[this.$options.name],
          newValue
        );
      }
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
